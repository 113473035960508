import { Zap, Shield, Cpu } from 'lucide-react';
import { StatsCard } from './StatsCard';
import { Title } from './Title';
import humanEyes from '/assets/human_eyes.png';
import { BackgroundCanvas } from './BackgroundCanvas';
import { MobileBackground3D } from './MobileBackground3D';
import { Terminal } from './Terminal';

export function Hero() {
  return (
    <>
      {/* Arrière-plan 3D avec z-index négatif */}
      <div className="fixed inset-0" style={{ zIndex: 0, pointerEvents: 'none' }}>
        <BackgroundCanvas />
      </div>
      
      {/* Contenu du Hero */}
      <div className="relative min-h-screen flex flex-col items-center justify-start sm:justify-center p-4 pt-16 sm:p-8" style={{ zIndex: 1 }}>
        <div className="text-center md:-translate-y-[5%] w-full px-4 sm:px-6">
          <div className="relative inline-block mb-6 sm:mb-8 md:mb-12">
            <div className="absolute inset-0 bg-cyan-500/30 rounded-full blur-3xl cyber-pulse" />
            <img 
              src={humanEyes}
              alt="HUMAN EYES"
              className="relative w-24 h-24 sm:w-32 sm:h-32 md:w-48 md:h-48 lg:w-80 lg:h-80 rounded-full object-cover scale-[1.2] sm:scale-[1.5] md:scale-[2] neon-border animate-float-cyber"
            />
          </div>
          
          <div className="space-y-6 sm:space-y-8 md:space-y-16">
            <div>
              <Title text="$INFECTAI" />
              <p className="text-base sm:text-xl md:text-3xl neon-text font-light mt-3 sm:mt-4 md:mt-8 cyber-pulse px-2 sm:px-4">
                Advanced token reports in under 4 minutes—faster 
                <br className="hidden sm:block"/>
                and smarter than anything you've seen before.
              </p>
            </div>

            <div className="flex flex-wrap justify-center gap-3 sm:gap-4 md:gap-8 px-2 sm:px-6">
              <StatsCard icon={Zap} text="Supply: 1B" />
              <StatsCard icon={Shield} text="Tax: 0%" />
              <StatsCard icon={Cpu} text="LP 100% Burned" />
            </div>
          </div>
        </div>

        <div className="w-full max-w-5xl mx-auto mt-12 sm:mt-16 space-y-6 sm:space-y-8 px-4 sm:px-6">
          <div className="text-center space-y-3 sm:space-y-4">
            <h2 className="horror-title text-2xl sm:text-3xl md:text-4xl" data-text="Access the future of crypto">Access the future of crypto</h2>
            <p className="horror-subtitle text-sm sm:text-base md:text-lg">THE AI OVERCLOCKED BY INFECTAI</p>
            {/* Modèle 3D sur mobile */}
            <div className="block sm:hidden mt-8 h-[300px] w-full relative overflow-hidden">
              <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent via-transparent to-black" />
              <MobileBackground3D />
            </div>
          </div>
        </div>
      </div>

      {/* Nouvelle section Terminal + Texte Horror */}
      <div className="relative min-h-screen flex items-center justify-center p-4 sm:p-6 md:p-8 md:-mt-80 pb-60" style={{ zIndex: 1 }}>
        <div className="w-full max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12 md:gap-8 items-start lg:items-center">
          {/* Partie texte à gauche */}
          <div className="space-y-4 sm:space-y-6 md:space-y-8 px-4 sm:px-6">
            <div className="space-y-3 sm:space-y-4">
              <h2 className="wtf-horror-title text-2xl sm:text-3xl md:text-4xl" data-text="INFECTAI AGENT">INFECTAI AGENT</h2>
              <p className="horror-subtitle text-base sm:text-lg md:text-2xl leading-relaxed">
                The next version of INFECTAI V1.1.0 is coming soon, and it's going to change the way you analyze tokens. 
                Powered by the infection, INFECTAI delivers full, advanced token reports in under 4 minutes—faster and smarter than anything you've seen before.
              </p>
            </div>
          </div>

          {/* Terminal à droite */}
          <div className="relative group px-4 sm:px-6 pb-20 md:pb-0 md:px-0">
            {/* Terminal avec perspective */}
            <div className="relative transform perspective-1000 group-hover:scale-[1.02] transition-transform duration-500">
              <Terminal />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}