import React from 'react';
import { Skull, Brain, Biohazard, Syringe } from 'lucide-react';

interface FeatureCardProps {
  icon: React.ElementType;
  title: string;
  description: string;
}

function FeatureCard({ icon: Icon, title, description }: FeatureCardProps) {
  return (
    <div className="bg-black/50 neon-border backdrop-blur-sm p-4 md:p-6 transform hover:scale-105 transition-all duration-300">
      <Icon className="w-8 h-8 md:w-10 md:h-10 text-red-500 mb-4" />
      <h3 className="text-lg md:text-xl font-bold text-red-500 mb-2">{title}</h3>
      <p className="text-red-400/80 text-sm md:text-base font-mono">{description}</p>
    </div>
  );
}

export function ZombieFeatures() {
  return (
    <div className="relative z-10 container mx-auto px-4 md:px-6 pb-36 md:pb-48 -mt-60">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
        <FeatureCard
          icon={Skull}
          title="VIRAL MARKETING"
          description="Spreading faster than the infection. One bite and you're hooked."
        />
        <FeatureCard
          icon={Brain}
          title="UNDEAD LIQUIDITY"
          description="LP tokens burned forever. Even zombies can't dig them up."
        />
        <FeatureCard
          icon={Biohazard}
          title="CONTAGIOUS GAINS"
          description="The most infectious gains in crypto. No cure available."
        />
        <FeatureCard
          icon={Syringe}
          title="ANTI-RUG VACCINE"
          description="Contract renounced. Not even patient zero can mutate it."
        />
      </div>
    </div>
  );
}