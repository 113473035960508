import { Canvas } from '@react-three/fiber';
import { Background3D } from './Background3D';
import { useEffect, useState } from 'react';

export function BackgroundCanvas() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Vérification initiale
    checkMobile();

    // Mise à jour lors du redimensionnement
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  if (isMobile) {
    return null;
  }

  return (
    <div className="absolute inset-0 h-full w-full" style={{ zIndex: 0, pointerEvents: 'none' }}>
      <Canvas
        camera={{ position: [0, 0, 10], fov: 75 }}
        style={{ 
          position: 'absolute', 
          touchAction: 'none',
          background: 'transparent',
          opacity: 0.7
        }}
      >
        <ambientLight intensity={0.3} />
        <directionalLight position={[10, 10, 5]} intensity={0.7} />
        <Background3D />
      </Canvas>
    </div>
  );
}
