import React from 'react';
import { Skull, DollarSign, TrendingUp, Zap } from 'lucide-react';

export function Footer() {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-20 bg-black/50 backdrop-blur-sm border-t border-red-500/30">
      <div className="container mx-auto px-4 md:px-6 py-3 md:py-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-3 md:gap-0">
          {/* Animated Stats */}
          <div className="flex gap-4 md:gap-8 text-sm md:text-base">
            <div>
              <div className="flex items-center gap-2">
                <TrendingUp className="w-4 h-4 md:w-5 md:h-5 text-red-500/70" />
                <span className="text-red-500/70 font-mono">+-% 24h</span>
              </div>
            </div>
            <div style={{ animationDelay: '0.2s' }}>
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4 md:w-5 md:h-5 text-red-500/70" />
                <span className="text-red-500/70 font-mono">$-K MCap</span>
              </div>
            </div>
          </div>

          {/* Animated Call to Action */}
          <div className="flex items-center gap-2 md:gap-4 order-first md:order-none">
            <div className="hidden md:block">
              <Skull className="w-5 h-5 md:w-6 md:h-6 text-red-500/70" />
            </div>
            <span className="text-red-500/70 font-bold text-sm md:text-base">
              INFECTAI: YOUR ULTIMATE TRADING COMPANION
            </span>
            <div className="hidden md:block" style={{ animationDelay: '0.3s' }}>
              <Zap className="w-5 h-5 md:w-6 md:h-6 text-red-500/70" />
            </div>
          </div>

          {/* Social Links */}
          <div className="flex gap-2 md:gap-4">
            <a 
              href="https://x.com/infectai" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-red-500 hover:text-red-400 transition-colors"
            >
              <div className="neon-border px-3 py-1 text-sm md:text-base md:px-4">
                TWITTER
              </div>
            </a>
            <a href="https://t.me/infectaionsol" className="text-red-500 hover:text-red-400 transition-colors">
              <div className="neon-border px-3 py-1 text-sm md:text-base md:px-4">
                TG
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}