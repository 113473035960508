import { useRef, useEffect, Suspense } from 'react';
import { useAnimations, PresentationControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';

function Model() {
  const modelRef = useRef<THREE.Group>();
  const { scene, animations } = useGLTF('./assets/human_6.glb');
  const { actions, names } = useAnimations(animations, scene);

  useEffect(() => {
    if (names.length > 0) {
      const action = actions[names[0]];
      if (action) {
        action.reset().play();
        action.timeScale = 1;
        action.setLoop(THREE.LoopRepeat, Infinity);
      }
    }
  }, [actions, names]);

  return (
    <PresentationControls
      global
      rotation={[0, 0, 0]}
      polar={[0, 0]}
      azimuth={[-Math.PI / 2, Math.PI / 2]}
      config={{ mass: 2, tension: 400 }}
      snap={{ mass: 4, tension: 400 }}
    >
      <primitive
        ref={modelRef}
        object={scene}
        scale={9.6}
        position={[0, -2.5, -6]}
        rotation={[0, -0.524, 0]}
      />
    </PresentationControls>
  );
}

function LoadingFallback() {
  return (
    <mesh>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color="red" />
    </mesh>
  );
}

export function Background3D() {
  return (
    <>
      <color attach="background" args={['#000000']} />
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={<LoadingFallback />}>
        <Model />
      </Suspense>
    </>
  );
}

useGLTF.preload('./assets/human_6.glb');
