import React from 'react';
import { Skull } from 'lucide-react';

interface FloatingMessageProps {
  text: string;
  className?: string;
}

export function FloatingMessage({ text, className = '' }: FloatingMessageProps) {
  return (
    <div className={`${className} bg-black/50 neon-border backdrop-blur-sm px-6 py-3 animate-float-cyber`}>
      <div className="flex items-center gap-2">
        <Skull className="w-5 h-5 text-red-500" />
        <p className="text-red-500 font-mono">&gt; {text}</p>
      </div>
    </div>
  );
}