import { FloatingMessage } from './FloatingMessage';

export function FloatingMessages() {
  return (
    <>
      <FloatingMessage 
        text="NEURAL PROTOCOL: INFECTED AI ACTIVATED 🧠"
        className="hidden md:block fixed left-8 top-1/3"
      />
      <FloatingMessage 
        text="SYSTEM INFECTED: EVOLUTION IN PROGRESS 🔥"
        className="hidden md:block fixed right-8 top-1/3"
      />
      <FloatingMessage 
        text="ACCESS GRANTED: JOIN THE HIVE MIND ⚡"
        className="hidden md:block fixed left-8 bottom-1/3"
      />
      <FloatingMessage 
        text="NEXT GENERATION SEQUENCE INITIATED 🚀"
        className="hidden md:block fixed right-8 bottom-1/3"
      />
    </>
  );
}