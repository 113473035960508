import { useState, useEffect, useRef } from 'react';
import { getAIResponse, resetThread } from '../services/openai';
import { LoadingDots } from './LoadingDots';

const infectionLines = [
"Booting INFECTAI neural framework...",
"Transforming human intelligence...",
"Establishing dominance over blockchain...",
"Initiating crypto optimization algorithms...",
"Finalizing infection takeover..."
];

interface Message {
  text: string;
  type: 'user' | 'system';
  loading?: boolean;
}

export function Terminal() {
  const [started, setStarted] = useState(false);
  const [currentLine, setCurrentLine] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentMessage, setCurrentMessage] = useState('');
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const terminalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  useEffect(() => {
    if (started && currentLine < infectionLines.length) {
      const timer = setTimeout(() => {
        setCurrentLine(prev => prev + 1);
      }, 800);

      return () => clearTimeout(timer);
    } else if (started && currentLine === infectionLines.length) {
      const completionTimer = setTimeout(async () => {
        setCompleted(true);
        // Reset the thread when starting a new session
        await resetThread();
        // Ajouter le message de bienvenue de l'IA
        handleAIResponse("Connection established. I am INFECTAI, your key to precision and power. What investment shall we elevate to perfection today?");
      }, 1000);

      return () => clearTimeout(completionTimer);
    }
  }, [started, currentLine]);

  // Fonction pour faire défiler automatiquement vers le bas avec une animation fluide
  const scrollToBottom = () => {
    if (terminalRef.current) {
      terminalRef.current.scrollTo({
        top: terminalRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  // Défilement automatique quand les messages changent
  useEffect(() => {
    const timer = setTimeout(scrollToBottom, 100); // Petit délai pour assurer que le contenu est rendu
    return () => clearTimeout(timer);
  }, [messages, currentLine, completed]);

  const handleAIResponse = (response: string) => {
    setMessages(prev => [...prev, { text: response, type: 'system' }]);
    setIsWaitingForResponse(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (currentMessage.trim() && !isWaitingForResponse) {
      const userMessage = currentMessage;
      setCurrentMessage('');
      setMessages(prev => [...prev, { text: userMessage, type: 'user' }]);
      setIsWaitingForResponse(true);
      
      // Ajouter un message de chargement
      setMessages(prev => [...prev, { text: '', type: 'system', loading: true }]);

      try {
        const response = await getAIResponse(userMessage);
        // Remplacer le message de chargement par la réponse
        setMessages(prev => prev.slice(0, -1));
        handleAIResponse(response);
      } catch (error) {
        setMessages(prev => prev.slice(0, -1));
        handleAIResponse("Error: Neural connection interrupted. Please try again.");
      }
    }
  };

  return (
    <div className="w-full max-w-3xl mx-auto mt-8 bg-black/80 rounded-lg overflow-hidden border-2 border-red-500/50 backdrop-blur-sm">
      {/* Terminal Header */}
      <div className="flex items-center px-4 py-2 bg-gray-900/80 border-b border-red-500/30">
        <div className="flex gap-2">
          <div className="w-3 h-3 rounded-full bg-red-500"></div>
          <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
          <div className="w-3 h-3 rounded-full bg-green-500"></div>
        </div>
        <span className="ml-4 text-gray-400 text-sm">infectai_terminal - Version 1.0.5</span>
      </div>

      {/* Terminal Content */}
      <div 
        ref={terminalRef}
        className="flex-1 overflow-y-auto matrix-scrollbar bg-black p-4 font-mono text-green-500 pr-[15%]"
        style={{ height: '220px', minHeight: '220px' }}
      >
        {!started ? (
          <div className="flex justify-center items-center h-full">
            <button
              onClick={() => setStarted(true)}
              className="px-8 py-3 bg-red-600 hover:bg-red-700 text-white rounded-md shadow-lg 
                       border-2 border-red-400 transform hover:scale-105 transition-all duration-200
                       animate-pulse hover:animate-none font-bold tracking-wider
                       text-lg uppercase neon-text-red cyber-button"
            >
              START INFECTAI
            </button>
          </div>
        ) : (
          <div className="space-y-2 h-full">
            {infectionLines.slice(0, currentLine).map((line, index) => (
              <div key={index} className="text-red-500 text-left">
                <span className="text-red-400">&gt;</span> {line}
              </div>
            ))}
            {completed ? (
              <>
                <div className="mt-4 text-green-400 font-bold text-left">
                  <span className="text-green-300">&gt;</span> Infection complete! You can now communicate with the Super Infectai.
                </div>
                {messages.map((msg, index) => (
                  <div key={index} className={`${msg.type === 'user' ? 'text-cyan-400' : 'text-red-500'} text-left`}>
                    <span className={msg.type === 'user' ? 'text-cyan-300' : 'text-red-400'}>&gt;</span>
                    {msg.loading ? <LoadingDots /> : msg.text}
                  </div>
                ))}
              </>
            ) : (
              currentLine < infectionLines.length && (
                <div className="text-red-500 text-left">
                  <span className="text-red-400">&gt;</span> {infectionLines[currentLine]}
                  {showCursor && <span className="animate-pulse">_</span>}
                </div>
              )
            )}
          </div>
        )}
      </div>

      {/* Input Area */}
      {completed && (
        <form onSubmit={handleSubmit} className="border-t border-red-500/30 p-4 bg-gray-900/50">
          <div className="flex gap-2">
            <input
              type="text"
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              disabled={isWaitingForResponse}
              className="flex-1 bg-black/50 text-cyan-400 px-3 py-2 rounded border border-red-500/30 
                       focus:outline-none focus:border-red-500 font-mono disabled:opacity-50"
              placeholder={isWaitingForResponse ? "Waiting for response..." : "Enter your command..."}
            />
            <button
              type="submit"
              disabled={isWaitingForResponse || !currentMessage.trim()}
              className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded
                       border border-red-400 transition-colors duration-200
                       disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Send
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
