import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LandingPage } from './components/LandingPage';
import { Hero } from './components/Hero';
import { Terminal } from './components/Terminal';
import { FloatingMessages } from './components/FloatingMessages';
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { ZombieFeatures } from './components/ZombieFeatures';
import { Background } from './components/Background';

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#000',
          color: '#ff0000'
        }}>
          <h1 style={{ marginBottom: '20px' }}>Something went wrong.</h1>
          <button
            onClick={() => {
              this.setState({ hasError: false });
              window.location.reload();
            }}
            style={{
              padding: '10px 20px',
              background: 'transparent',
              border: '2px solid #ff0000',
              color: '#ff0000',
              cursor: 'pointer',
              fontSize: '16px'
            }}
          >
            Try again
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

const LoadingScreen = () => (
  <div style={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#000',
    color: '#ff0000'
  }}>
    Loading...
  </div>
);

const HomePage = () => (
  <ErrorBoundary>
    <Suspense fallback={<LoadingScreen />}>
      <Background />
      <div className="min-h-screen bg-transparent text-white relative overflow-hidden">
        <Navbar />
        <Hero />
        <ZombieFeatures />
        <FloatingMessages />
        <Footer />
      </div>
    </Suspense>
  </ErrorBoundary>
);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;