import { Canvas } from '@react-three/fiber';
import { Background3D } from './Background3D';

export function MobileBackground3D() {
  return (
    <div className="w-full h-full relative">
      <Canvas
        camera={{ position: [0, 0, 10], fov: 75 }}
        style={{ 
          touchAction: 'none',
          background: 'transparent',
          opacity: 0.7
        }}
      >
        <ambientLight intensity={0.3} />
        <directionalLight position={[10, 10, 5]} intensity={0.7} />
        <Background3D />
      </Canvas>
    </div>
  );
}
