import React from 'react';
import { Skull } from 'lucide-react';

export function Navbar() {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 border-b border-red-500/30 backdrop-blur-sm">
      <div className="container mx-auto px-4 md:px-6 py-3 md:py-4 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <Skull className="w-6 h-6 md:w-8 md:h-8 text-red-500" />
          <span className="text-xl md:text-2xl font-bold text-red-500">INFECTAI</span>
        </div>
        <button className="bg-transparent neon-border text-red-500 px-4 md:px-6 py-1.5 md:py-2 rounded-none text-sm md:text-base font-bold transition-all duration-300 hover:bg-red-500/20">
        LAUNCH IN PROGRESS
        </button>
      </div>
    </nav>
  );
}