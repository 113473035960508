import React from 'react';
import { Skull } from 'lucide-react';

export function Background() {
  return (
    <>
      {/* Fond noir avec grille cyber */}
      <div className="fixed inset-0 bg-black cyber-grid opacity-20" style={{ zIndex: -2 }} />
      
      {/* Fond rouge animé */}
      <div className="fixed inset-0 bg-gradient-to-br from-red-900/20 via-transparent to-red-900/20 animate-gradient-shift" style={{ zIndex: -1 }} />
      
      <div className="fixed inset-0 overflow-hidden" style={{ zIndex: 0 }}>
        {/* Lignes rouges horizontales animées */}
        {[...Array(30)].map((_, i) => (
          <div
            key={`horizontal-line-${i}`}
            className="absolute h-[1px] w-full"
            style={{
              top: `${(i * 100) / 30}%`,
              background: 'linear-gradient(90deg, transparent, rgba(255, 0, 0, 0.5), transparent)',
              animation: 'slide-left-right 8s linear infinite',
              animationDelay: `${i * 0.2}s`,
              opacity: 0.3
            }}
          />
        ))}

        {/* Lignes rouges verticales animées */}
        {[...Array(30)].map((_, i) => (
          <div
            key={`vertical-line-${i}`}
            className="absolute w-[1px] h-full"
            style={{
              left: `${(i * 100) / 30}%`,
              background: 'linear-gradient(180deg, transparent, rgba(255, 0, 0, 0.5), transparent)',
              animation: 'slide-top-bottom 8s linear infinite',
              animationDelay: `${i * 0.2}s`,
              opacity: 0.3
            }}
          />
        ))}

        {/* Blood drips */}
        {[...Array(10)].map((_, i) => (
          <div
            key={`drip-${i}`}
            className="absolute w-[2px] bg-gradient-to-b from-transparent via-red-600 to-red-900"
            style={{
              left: `${(i * 100) / 10}%`,
              height: '50vh',
              opacity: 0.3,
              animationDelay: `${i * 0.2}s`,
              animation: 'blood-drip 4s linear infinite'
            }}
          />
        ))}

        {/* Cyber lines */}
        {[...Array(20)].map((_, i) => (
          <div
            key={`line-${i}`}
            className="absolute h-[1px] w-full bg-gradient-to-r from-transparent via-red-600 to-transparent cyber-pulse"
            style={{
              top: `${(i * 100) / 20}%`,
              animationDelay: `${i * 0.1}s`,
              opacity: 0.3
            }}
          />
        ))}

        {/* Skull patterns */}
        {[...Array(15)].map((_, i) => (
          <Skull
            key={`skull-${i}`}
            className="absolute text-red-900/20"
            style={{
              width: `${30 + Math.random() * 50}px`,
              height: `${30 + Math.random() * 50}px`,
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        ))}

        {/* Glowing orbs */}
        <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-red-500/20 rounded-full blur-3xl cyber-pulse" />
        <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-red-900/20 rounded-full blur-3xl cyber-pulse animation-delay-2000" />
      </div>
    </>
  );
}