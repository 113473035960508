import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Canvas, useFrame } from '@react-three/fiber';
import { 
  EffectComposer,
  Bloom,
  ChromaticAberration,
  Noise,
  Vignette
} from '@react-three/postprocessing';
import { Suspense } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import * as THREE from 'three';

// Composant pour les particules
function Particles({ count = 5000 }) {
  const mesh = useRef();

  const [positions, colors] = React.useMemo(() => {
    const positions = new Float32Array(count * 3);
    const colors = new Float32Array(count * 3);

    for (let i = 0; i < count; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 10;

      colors[i * 3] = Math.random();
      colors[i * 3 + 1] = Math.random() * 0.3;
      colors[i * 3 + 2] = Math.random() * 0.3;
    }

    return [positions, colors];
  }, [count]);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    mesh.current.rotation.x = time * 0.1;
    mesh.current.rotation.y = time * 0.1;
  });

  return (
    <points ref={mesh}>
      <bufferGeometry>
        <bufferAttribute
          attachObject={['attributes', 'position']}
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attachObject={['attributes', 'color']}
          count={colors.length / 3}
          array={colors}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.02}
        vertexColors
        blending={THREE.AdditiveBlending}
        transparent
      />
    </points>
  );
}

// Composant principal
export const LandingPage = () => {
  const navigate = useNavigate();
  const [isEntering, setIsEntering] = useState(false);

  const handleEnter = () => {
    setIsEntering(true);
    setTimeout(() => {
      navigate('/home');
    }, 1000);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="landing-page"
      >
        <Canvas
          camera={{ position: [0, 0, 5], fov: 75 }}
          className="absolute inset-0"
        >
          <Suspense fallback={null}>
            <color attach="background" args={['#000000']} />
            <fog attach="fog" args={['#000000', 1, 10]} />
            
            <Particles />

            <ambientLight intensity={0.2} />
            <pointLight position={[10, 10, 10]} intensity={1} />

            <EffectComposer>
              <Bloom
                intensity={1.5}
                luminanceThreshold={0.1}
                luminanceSmoothing={0.9}
              />
              <ChromaticAberration offset={[0.002, 0.002]} />
              <Noise opacity={0.1} />
              <Vignette darkness={0.5} />
            </EffectComposer>
          </Suspense>
        </Canvas>

        <motion.div
          className="absolute inset-0 flex flex-col items-center justify-center z-10"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        >
          <motion.h1
            className="text-6xl font-bold mb-8 text-red-500 cyber-text"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            INFECTAI
          </motion.h1>
          
          <motion.button
            className="enter-button"
            onClick={handleEnter}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            disabled={isEntering}
          >
            Enter
          </motion.button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
