import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

let currentThread: any = null;

export async function getAIResponse(userMessage: string): Promise<string> {
  try {
    // Create a new thread if none exists
    if (!currentThread) {
      currentThread = await openai.beta.threads.create();
    }

    // Add the user's message to the thread
    await openai.beta.threads.messages.create(
      currentThread.id,
      {
        role: "user",
        content: userMessage,
      }
    );

    // Create a run with the assistant
    const run = await openai.beta.threads.runs.create(
      currentThread.id,
      {
        assistant_id: import.meta.env.VITE_OPENAI_ASSISTANT_ID as string,
      }
    );

    // Poll for the run completion
    let runStatus = await openai.beta.threads.runs.retrieve(
      currentThread.id,
      run.id
    );

    // Wait for the run to complete
    while (runStatus.status === "queued" || runStatus.status === "in_progress") {
      await new Promise(resolve => setTimeout(resolve, 1000));
      runStatus = await openai.beta.threads.runs.retrieve(
        currentThread.id,
        run.id
      );
    }

    // Get the messages after the run completes
    const messages = await openai.beta.threads.messages.list(
      currentThread.id
    );

    // Get the latest assistant message
    const lastMessage = messages.data
      .filter(message => message.role === "assistant")
      .pop();

    if (!lastMessage || !lastMessage.content[0]) {
      throw new Error("No response from assistant");
    }

    return lastMessage.content[0].text.value;

  } catch (error) {
    console.error("Error in getAIResponse:", error);
    return "Error: Neural connection interrupted. Please try again.";
  }
}

// Function to reset the thread
export async function resetThread() {
  currentThread = null;
}
