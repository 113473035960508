interface TitleProps {
  text: string;
}

export function Title({ text }: TitleProps) {
  return (
    <h1 className="cyberpunk-title" data-text={text}>
      {text}
    </h1>
  );
}